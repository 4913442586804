import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment, lazy, Suspense, useMemo } from 'react';
import { isContent, isLayout, } from '../types/dynamicComponent.types';
import { ComponentController } from './componentController';
import { useModuleVisibility } from './hooks/useModuleVisibility';
import { AttendeeComponentErrorBoundary } from './AttendeeComponentErrorBoundary';
const componentList = {
    AddEvent: lazy(() => import('@backstage-components/add-event').then(({ AddEventComponent }) => ({
        default: AddEventComponent,
    }))),
    BackgroundVideo: lazy(() => import('@backstage-components/background-video').then(({ BackgroundVideoComponent }) => ({
        default: BackgroundVideoComponent,
    }))),
    ButtonOverlay: lazy(() => import('@backstage-components/button-overlay').then(({ ButtonOverlayComponent }) => ({
        default: ButtonOverlayComponent,
    }))),
    Carousel: lazy(() => import('@backstage-components/carousel').then(({ CarouselComponent }) => ({
        default: CarouselComponent,
    }))),
    CollectionList: lazy(() => import('@backstage-components/collection-list').then(({ CollectionListComponent }) => ({ default: CollectionListComponent }))),
    Container: lazy(() => import('@backstage-components/container-layout').then(({ ContainerLayout }) => ({ default: ContainerLayout }))),
    Drawer: lazy(() => import('@backstage-components/drawer').then(({ DrawerComponent }) => ({
        default: DrawerComponent,
    }))),
    Modal: lazy(() => import('@backstage-components/modal').then(({ ModalComponent }) => ({
        default: ModalComponent,
    }))),
    PeacockBackground: lazy(() => import('@backstage-components/peacock-background').then(({ PeacockBackgroundComponent }) => ({
        default: PeacockBackgroundComponent,
    }))),
    RtcRoomContainer: lazy(() => import('@backstage-components/rtc-room-container').then(({ RtcRoomContainerComponent }) => ({ default: RtcRoomContainerComponent }))),
    Stacked: lazy(() => import('@backstage-components/stack-layout').then(({ StackLayout }) => ({
        default: StackLayout,
    }))),
    Style: lazy(() => import('@backstage-components/style-layout').then(({ StyleLayout }) => ({
        default: StyleLayout,
    }))),
    Video: lazy(() => import('@backstage-components/video').then(({ VideoComponent }) => ({
        default: VideoComponent,
    }))),
};
export const LayoutController = (definition) => {
    const props = useMemo(() => Object.assign({ slotRenderer: SlotRender }, definition), [definition]);
    const shouldRenderModule = useModuleVisibility(definition);
    if (definition.config.scope === 'attendee') {
        if (!shouldRenderModule) {
            return _jsx(Fragment, {}, definition.id);
        }
    }
    if (componentList[definition.component]) {
        // Not sure how to make type of `DefineComponent` match `definition` but
        // `componentList` properly checks that only an appropriate Component can be
        // assigned so should be safe
        // biome-ignore lint/suspicious/noExplicitAny: see above
        const DefinedComponent = componentList[definition.component];
        return (_jsx(AttendeeComponentErrorBoundary, { children: _jsx(Suspense, { fallback: _jsx(Fragment, {}), children: _jsx(DefinedComponent, { ...props }) }) }));
    }
    else {
        return _jsx("div", { children: definition.component });
    }
};
const SlotRender = (component) => {
    if (isLayout(component)) {
        return _jsx(LayoutController, { ...component });
    }
    else if (isContent(component)) {
        return _jsx(ComponentController, { ...component });
    }
    else {
        return null;
    }
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.possibleTypes = void 0;
exports.possibleTypes = {
    "GuestExternalVerifyResult": [
        "DataIntegrityError",
        "GuestExternal"
    ],
    "GuestStorageUpdateResult": [
        "DataIntegrityError",
        "Guest",
        "GuestExternal",
        "GuestStorageUnavailableError",
        "InvalidOperationError",
        "NotAuthorizedError"
    ],
    "IError": [
        "AssetFinalizedError",
        "AssetNotFoundError",
        "AssetUnsupportedError",
        "AttendeeBulkCreateError",
        "DataIntegrityError",
        "GuestInvalidUpdateError",
        "GuestNotFoundError",
        "GuestStorageUnavailableError",
        "InsufficientPermissionsError",
        "InvalidOperationError",
        "NoAccountError",
        "NotAuthorizedError",
        "SavedInstructionColorError",
        "SiteVariableInvalidError",
        "TooManyOrganizationsError",
        "UnknownError"
    ],
    "IGuest": [
        "Guest",
        "GuestAnonymous",
        "GuestExternal",
        "SelfResult"
    ],
    "ISiteVariable": [
        "SiteVariableColor",
        "SiteVariableDate",
        "SiteVariableImage",
        "SiteVariableLink",
        "SiteVariableNumber",
        "SiteVariableRichText",
        "SiteVariableShow",
        "SiteVariableSwitch",
        "SiteVariableText",
        "SiteVariableVideo"
    ],
    "IVideoVariableValue": [
        "SiteVariableVideoDashValue",
        "SiteVariableVideoHlsDashValue",
        "SiteVariableVideoHlsValue",
        "SiteVariableVideoProgressiveHlsValue",
        "SiteVariableVideoProgressiveValue"
    ],
    "Resource": [
        "LinkResource",
        "ScriptResource"
    ],
    "SiteVariable": [
        "SiteVariableColor",
        "SiteVariableDate",
        "SiteVariableImage",
        "SiteVariableLink",
        "SiteVariableNumber",
        "SiteVariableRichText",
        "SiteVariableShow",
        "SiteVariableSwitch",
        "SiteVariableText",
        "SiteVariableVideo"
    ],
    "SiteVariableVideoValue": [
        "SiteVariableVideoDashValue",
        "SiteVariableVideoHlsDashValue",
        "SiteVariableVideoHlsValue",
        "SiteVariableVideoProgressiveHlsValue",
        "SiteVariableVideoProgressiveValue"
    ],
    "TriggerResendAccessCodeResult": [
        "DataIntegrityError",
        "EmailTrigger"
    ],
    "VerifyOpenLoginResult": [
        "DataIntegrityError",
        "OpenLoginGuest"
    ],
    "VerifyPublicPasscodeResult": [
        "DataIntegrityError",
        "PublicPasscodeOutput"
    ]
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetSiteAssociationJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const asset_site_model_1 = require("./asset-site-model");
function fromJson(item, mode) {
    const isAssetSiteItem = (0, json_schema_1.createValidator)(asset_site_model_1.AssetSiteAssociation);
    if (isAssetSiteItem(item)) {
        return {
            assetId: item.assetId,
            assetType: item.assetType,
            siteId: item.siteId,
            usageCount: item.usageCount,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isAssetSiteItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `AssetSiteAssociation`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `AssetSiteAssociation`.
 */
function toJson(record) {
    return {
        assetId: record.assetId,
        assetType: record.assetType,
        siteId: record.siteId,
        usageCount: record.usageCount,
    };
}
exports.AssetSiteAssociationJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};

import type {GuestAuthChangeEvent} from '@backstage-components/base';
import {useCallback, useRef, useSyncExternalStore} from 'react';

/** Type helper to extract the detail information from a `CustomEvent` */
type CustomEventDetail<T> = T extends CustomEvent<infer Detail>
  ? Detail
  : never;

/** Detail of the GuestAuthChangeEvent */
type AuthChangeDetail = CustomEventDetail<GuestAuthChangeEvent>;

/** Attendee or Guest information out of the `GuestAuthChangeEvent` */
type Attendee = AuthChangeDetail['attendee'];

/**
 * Listen for changes to the currently authenticated guest and trigger a
 * re-render when the guest information changes.
 */
export function useAuthenticatedGuest(): Attendee | null {
  const attendeeRef = useRef<Attendee>(null);
  const getSnapshot: Reader = useCallback(() => attendeeRef.current, []);
  const subscriber: Subscriber = useCallback((onChange) => {
    const listener = (e: GuestAuthChangeEvent): void => {
      const existing = attendeeRef.current;
      const next = e.detail.attendee;
      if (
        (existing === null && next !== null) ||
        (existing !== null && next === null) ||
        (existing !== null && next !== null && existing.id !== next.id)
      ) {
        attendeeRef.current = next;
        onChange();
      }
    };
    document.body.addEventListener('GuestAuth:change', listener);
    return () => {
      document.body.addEventListener('GuestAuth:change', listener);
    };
  }, []);
  const value = useSyncExternalStore(subscriber, getSnapshot);
  return value;
}

/** Type alias for subscriber that notifies of changes to authenticated guest */
type Subscriber = Parameters<typeof useSyncExternalStore<Attendee | null>>[0];

/** Type alias for reader that gets snapshot of current authenticated guest */
type Reader = Parameters<typeof useSyncExternalStore<Attendee | null>>[1];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetSiteAssociation = void 0;
const typebox_1 = require("@sinclair/typebox");
const asset_model_1 = require("../asset/asset-model");
exports.AssetSiteAssociation = typebox_1.Type.Object({
    /**
     * Identifies the asset associated with the site
     */
    assetId: typebox_1.Type.String({}),
    /**
     * Identifies the site to which the asset is associated
     */
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    /**
     * The type of asset, this is a convenience in order to avoid a join or second
     * lookup in order to find the `assetType` based on `assetId`
     */
    assetType: asset_model_1.AssetType,
    /**
     * The number of times the asset is referenced in the site. Once an asset has
     * been associated it will be easier to decrement or increment an existing
     * association than add and delete them.
     */
    usageCount: typebox_1.Type.Integer(),
}, { $id: 'AssetSiteAssociation' });

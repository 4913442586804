import { Component } from 'react';
export class AttendeeComponentErrorBoundary extends Component {
    state = {
        hasError: false,
    };
    static getDerivedStateFromError(_) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }
    render() {
        if (this.state.hasError) {
            // if desired, we can just silently render nothing
            return this.props.fallback ?? null;
        }
        return this.props.children;
    }
}
